
<template>
<v-app>
    <dashboard-core-view />

    <dashboard-core-settings />
    
    
<div style="background-color:#1e1e1e94; height:100%">

  <div class="text-center" style="text-align: center;">
        
        </br>

        <div style="color:orange; font-size:64px">
          Página não encontrada
        </div>

     

         <div style="color:white; font-size:16px">
                  Desculpa, a página que você está procurando não existe nesse site 
        </div>
        
        <div class="text-center" style="text-align: center; margin-top:20px" >
                <v-btn  to="/" color="sucess" class="white--text"><b>Voltar</b></v-btn>
        </div>

  </div>

    <div class="text-center" style="text-align: center; widht:300px">
      <img src="https://maestrix.grupoparque.com.br/LOGOSVG4.svg" width="300" height="220" style="margin-top:12px">

  </div>



</div>
</v-app>

</template>

<script>
export default {
  name: 'Page404'
}
</script>